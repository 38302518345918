<template>
    <div>
      <a-card>
        <template slot="title">
          <strong class="mr-4">Konfigurasi Kas Teller</strong>
          <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
          <!-- <a-button
            type="button"
            class="btn btn-outline-primary ml-2"
            @click="connector('nonanggota')"
          >
            Tambah Non Anggota
          </a-button> -->
          <a-button-group>
          <a-button
            type="button"
            class="btn btn-outline-primary "
            @click="showModal('create', {})"
          >
            Tambah
          </a-button>
              <a-button
                class="btn btn-outline-success"
                @click="getAllData(true)"
                >Refresh Data</a-button
              >
          </a-button-group>
        </template>
        <a-table
          :columns="columns"
          :dataSource="data"
        style="margin: -25px"
          :pagination="{
            hideOnSinglePage:true,
            defaultPageSize: 10999,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
          size="small"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          ></a-icon>
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              {{ text }}
            </template>
          </template>
          <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
          <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
          <span slot="tags" slot-scope="tags">
            <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
              <a-icon type="edit"></a-icon>
            </a>
            <a-divider type="vertical" />
            <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
              <a-icon type="delete"></a-icon>
            </a>
            <!-- <a href="javascript:;" class="ant-dropdown-link">
              More actions <a-icon type="down" />
            </a> -->
          </span>
          <span slot="jenis_identitas" slot-scope="text, record">
            {{ findRelationData(record, 'masterJenisIndentitas', 'id', 'jenis_identitas', 'keterangan') }}
          </span>
          <span slot="tgl" slot-scope="text">
            {{ formatDate(text) }}
          </span>
        </a-table>
      </a-card>
      <a-modal v-model="formvisible" title="Modal Form Tambah User" :maskClosable="false">
        <template slot="footer">
          <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
          <a-button class="" type="primary" @click="handleSubmit">Submit</a-button>
        </template>
        <div class="row">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="mt-1">Pilih User</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              :key="componentKey"
              placeholder="Ketikan User"
              label="label"
              v-model="modalInput.user_id"
              :options="masterUsers"
              :reduce="(tes) => tes.id"
            />
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="mt-1">Pilih Kode Akun</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              :key="componentKey"
              placeholder="Ketikan Kode Kas"
              label="label"
              v-model="modalInput.kode_kas"
              :options="masterRekening"
              :reduce="(tes) => tes.kode"
            />
          </div>
        </div>
      </a-modal>
    </div>
  </template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Username',
    dataIndex: 'email',
    width: 200,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.email.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Kode Kas Teller',
    dataIndex: 'kode_kas',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode_kas.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    width: 200,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created () {
    this.getMaster()
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'KonfigurasiKasTeller',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      masterUsers: [],
      backupmasterUsers: [],
      masterRekening: [],
      backupmasterRekening: [],
      modalInput: {
        user_id: '',
        name: '',
        email: '',
        kode_kas: '',
        keterangan: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    connectValue(firstInput, secondInput) {
      var target = this.masterUsers.findIndex(
        (x) => x.id === this.modalInput[firstInput],
      )
      // console.log('target', target)
      if (target !== -1) {
        // console.log('this.masterUsers[target]', this.masterUsers[target])
        this.modalInput[secondInput] = this.masterUsers[target].kode
      }
    },
    showModal(action, data) {
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.modalInput.keterangan = this.modalInput.keterangan.replaceAll('&nbsp;', '')
        this.editdata = data
      } else {
        this.action = 'create'
        this.modalInput = {
          user_id: '',
          name: '',
          email: '',
          kode_kas: '',
          keterangan: '',
        }
      }
    },
    async getMaster() {
      var resUsers = await lou.customUrlGet2('config/users')
      var resAkun = await lou.customUrlGet2('config/akun')
      if (resUsers) {
        this.masterUsers = []
        resUsers.data.data.forEach(element => {
        // if (element.id === 211) {
        // console.log('element', element)
        // }
          var nobj = {
            label: element.email + ' - ' + element.name,
            ...element,
          }
          this.masterUsers.push(nobj)
        })
        this.backupmasterUsers = this.$g.clone(this.masterUsers)
      }
      if (resAkun) {
        this.masterRekening = []
        resAkun.data.forEach(element => {
        // if (element.id === 211) {
        // console.log('element', element)
        // }
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.masterRekening.push(nobj)
        })
        this.backupmasterRekening = this.$g.clone(this.masterRekening)
      }
    },
    async getAllData(onclick = false) {
      // console.log('this.masterUsers', this.masterUsers)
      var response = await lou.customUrlGet2('config')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      this.componentKey += 1
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('config/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var targetuser = this.backupmasterUsers.findIndex(x => x.id === this.modalInput.user_id)
      var targetrekening = this.backupmasterRekening.findIndex(x => x.kode === this.modalInput.kode_kas)
      var fd = {
        user_id: this.modalInput.user_id,
        name: targetuser !== -1 ? this.backupmasterUsers[targetuser].name : '',
        email: targetuser !== -1 ? this.backupmasterUsers[targetuser].email : '',
        kode_kas: targetrekening !== -1 ? this.backupmasterRekening[targetrekening].kode : '',
        keterangan: targetrekening !== -1 ? this.backupmasterRekening[targetrekening].keterangan : '',
      }
      var res = false
      if (this.action === 'update') {
        fd.id = this.editdata.id
        res = await lou.customUrlPut2('config', fd, false)
      } else {
        delete fd.id
        res = await lou.customUrlPost2('config', fd, false)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        user_id: '',
        name: '',
        email: '',
        kode_kas: '',
        keterangan: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Kas Teller.xlsx'
      NProgress.done()
      link.click()
    },
  },
}
</script>

  <style scoped>
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
  </style>
